import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import Pagination from "./../../components/Pagination";
import { useCreator } from "../../hooks/storeHooks";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import { fetchData } from "../../utils/api/apiHelper";
import { ListContentSharedwith } from "../../utils/api/actionConstants";
import VideoPlayer from "../../components/VideoPlayer";
import { CleanupViewFiles } from "../../redux/creatorContent";
import { getDateFull, getDate } from "../../utils/Common";

const FilesSharedWithConsumer = (props) => {
  const [NavigateTo] = useCustomNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 10;
  const localtion = useLocation();

  const dispatch = useDispatch();
  const Creator = useCreator();

  useEffect(() => {
    if (
      !localtion.state ||
      !localtion.state.creator ||
      !localtion.state.creator.id
    ) {
      NavigateTo("/");
    }
  }, []);

  const creator = localtion.state.creator;
  const consumer = localtion.state.consumer;

  useEffect(() => {
    dispatch(
      fetchData({
        action: ListContentSharedwith,
        data: {
          creator: creator.id,
          consumer: consumer?.id,
          limit: 10,
          offset: (currentPage - 1) * PageSize,
        },
      })
    );
  }, [dispatch, currentPage]);

  /**
   * this hook is for cleaning up the redux store when we move out.
   */
  useEffect(() => () => dispatch(CleanupViewFiles()), []);

  let content = Creator.content;
  let currentDate;
  if (content.length > 0) {
    currentDate = new Date(getDate(content[0].createdAt));
  }

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="d-flex aic jsb flw">
                  <h1 className="pageHeading my-4">Content Shared</h1>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="d-flex aic jsb flw">
                      <h3 className="pageTitle">
                        Shared Between{" "}
                        <span className="sharedBy">{creator?.name}</span> and{" "}
                        <span className="sharedWith">{consumer?.name}</span>
                      </h3>
                    </div>
                    {content &&
                      content.length > 0 &&
                      content.map((v, k) => {
                        let show = false;
                        if (currentDate > new Date(getDate(v.createdAt))) {
                          show = true;
                          currentDate = new Date(getDate(v.createdAt));
                        }

                        if (v.type === "video") {
                          return (
                            <>
                              {(show || k == 0) && (
                                <h3 className="pageTitle shareddate" key={k + "1"}>
                                  {getDateFull(v.createdAt)}
                                </h3>
                              )}
                              <div className="col-3" key={k}>
                                <div className="card tac h-100">
                                  <div className="card-body lib-video h-100">
                                    <VideoPlayer url={v.source} />
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        } else {
                          return (
                            <>
                              {(show || k == 0) && (
                                <h3 className="pageTitle shareddate" key={k + "1"}>
                                  {getDateFull(v.createdAt)}
                                </h3>
                              )}
                              <div className="col-3" key={k}>
                                <div className="card tac">
                                  <div className="card-body lib-img">
                                    {" "}
                                    <img src={v.source} alt="" />{" "}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })}
                  </div>
                  <div className="row jse my-4">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={Creator.totalContent}
                      pageSize={PageSize}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FilesSharedWithConsumer;
