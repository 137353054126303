import validator from "validator";
var _ = require("lodash");

let rl = {
  Login: {
    email: ["Req", "Email", ""],
    password: ["Req", "String", ""],
  },
  RegisterConsumer: {
    name: ["Req", "String", ""],
    email: ["Req", "Email", ""],
    password: ["Req", "String", ""],
    userType: ["Req", "String", ""],
  },
  SendEmail: {
    email: ["Req", "Email", ""],
  },
  RegisterCreator: {
    name: ["Req", "String", ""],
    email: ["Req", "Email", ""],
    password: ["Req", "String", ""],
    userType: ["Req", "String", ""],
    age: ["Req", "String", ""],
    bodyType: ["Req", "String", ""],
    zipcode: ["Req", "String", ""],
    heading: ["Req", "String", ""],
    ethnicity: ["Req", "String", ""],
    aboutMe: ["Req", "String", ""],
    tags: ["Req", "String", ""],
    exclusion_zip: ["Req", "String", ""],
  },
  ResetPassword: {
    hash: ["Req", "String", ""],
    password: ["Req", "String", ""],
  },
  UpdateOrder: {
    InvoiceDate: ["Req", "String", ""],
    InvoiceNo: ["Req", "String", ""],
    InvoicePrice: ["Req", "String", ""],
    State: ["Req", "String", ""],
    VendorGST: ["Req", "GST", ""],
    Remark: ["Req", "String", ""],
    TaxableAmount: ["Req", "Number", ""],
    TotalPrice: ["Req", "Number", ""],
    TotalTax: ["Req", "Number", ""],
  },
  AddCustomer: {
    id: ["No", "Number", ""],
    company_name: ["Req", "String", ""],
    contactPerson: ["Req", "String", ""],
    email: ["Req", "Email", ""],
    // phone: ["Req", "Mobile", ""],
    city: ["Req", "String", ""],
    state: ["Req", "String", ""],
    gstno: ["no", "GST", ""],
    installAddress: ["Req", "String", ""],
    code: ["Req", "String", ""],
    pinCode: ["Req", "PinCode", ""],
    // mobile: ["Req", "Mobile", ""],
  },
  UpdateCustomer: {
    id: ["No", "Number", ""],
    company_name: ["Req", "String", ""],
    contactPerson: ["No", "String", ""],
    email: ["No", "Email", ""],
    phone: ["No", "Mobile", ""],
    city: ["Req", "String", ""],
    state: ["Req", "String", ""],
    gstno: ["No", "GST", ""],
    installAddress: ["Req", "String", ""],
    code: ["No", "String", ""],
    pinCode: ["No", "PinCode", ""],
  },
  CreateCompany: {
    companyname: ["Req", "String", ""],
    code: ["No", "String", ""],
    location: ["Req", "id", ""],
    floor: ["Req", "id", ""],
    area: ["Req", "area", ""],
    contract_start_date: ["Req", "date", ""],
    contract_end_date: ["Req", "date", ""],
    regnumber: ["Req", "number", ""],
    pri_person_name: ["Req", "String", ""],
    pri_person_number: ["Req", "number", ""],
    sec_person_name: ["No", "String", ""],
    sec_person_number: ["No", "number", ""],
    pri_email: ["Req", "Email", ""],
    sec_email: ["No", "Email", ""],
    pan: ["Req", "number", ""],
    gst: ["Req", "number", ""],
    logo_url: ["No", "String", ""],
    builtupUnitTypeId: ["Req", "id", ""],
    area: ["Req", "number", ""],
    name: ["No", "String", ""],
  },
  CreateLocation: {
    Address: ["Req", "String", ""],
    State: ["Req", "state", ""],
    City: ["Req", "city", ""],
    Type: ["Req", "type", ""],
  },

  Reports: {
    startdate: ["Req", "String", ""],
    endDate: ["Req", "String", ""],
    status: ["Req", "String", ""],
  },

  PostFeed: {
    title: ["Req", "String", ""],
    description: ["Req", "String", ""],
  },
};

export function validateXerox(data) {
  // if whole State is passed then we can Build the validation field here from
  // Our OM App.
  let error = "";
  for (const [key, value] of Object.entries(data)) {
    let err = checkemptyvalue(value);
    if (err === 0) {
      error = key + " can't be Blank";
      break;
    }
  }
  return error;
}

export function checkemptyvalue(value) {
  if (value === undefined || value === "") {
    return 0;
  }
  return 1;
}

export function dynamicValidationNew(page, data) {
  if (data.length === 0) {
    return "Please Pass Object to Validate";
  }

  if (_.size(rl[page]) < 1) return "";
  return validateBlank(rl[page], data);
}

let Required = 0;
function validateBlank(frules, data) {
  let error = "";
  for (const [key, value] of Object.entries(data)) {
    Required = 0;
    if (Array.isArray([1, 2, 3])) {
      let singleRules = frules[key];
      if (singleRules && singleRules.length > 0) {
        let Req = singleRules[0];
        let DType = singleRules[1];
        let Length = singleRules[2];
        // if Req is no Then Dont check for Req.
        if (Req === "Req") {
          Required = 1;
          let err = checkemptyvalue(value);
          if (err !== 1) {
            error = key.toUpperCase() + " can't be Blank";
            break;
          }
        }
        // Check DType if its not blank.
        if (DType !== "" && DType !== undefined) {
          let err = checkDataType(DType, value);
          if (err !== 1) {
            error = key.toUpperCase() + err;
            break;
          }
        }
        // check the String Length if not Blank.
        if (Length !== "" && Length !== undefined) {
          // let err = checkLength(DType, Length, value);
          // if (err !== 1) {
          //     error = key.toUpperCase() + err;
          //     break;
          // }
        }
      } else {
        let err = checkemptyvalue(value);
        if (err === 0) {
          error = key.toUpperCase() + " can't be Blank";
          break;
        }
      }
    } else {
      if (frules[key] === "Req") {
        let err = checkemptyvalue(value);
        if (err === 0) {
          error = key.toUpperCase() + " can't be Blank";
          break;
        }
      }
    }
  }

  return error;
}

function checkLength(length, value) {
  // if value is not string convert into string.
  if (typeof value !== "string" && typeof value !== "number") return 1;

  if (typeof value === "string") {
    if (value.length < length)
      return " Can Only be of " + length + " Character";
  } else {
    if (value.toString().length < length)
      return " Can Only be of " + length + " Digit";
  }
}

export function checkDataType(DType, value) {
  let resp = 1;
  if (Required === 0) {
    if (!value || value === "") {
      return resp;
    }
  }

  switch (DType) {
    case "Number":
      if (value === null || isNaN(value)) {
        resp = " Can Only be Number.";
      }

      if (value < 0) {
        resp = " Number Can't Be Lower then 0.";
      }
      // Check if String is only number or not.
      break;
    // case "MSN":
    //   if (value === null || isNaN(value)) {
    //     resp = " Can Only be Number.";
    //   }

    //   if (typeof value != "string") {
    //     value = value.toString();
    //   }

    //   if (value.length != 10) {
    //     resp = " Can Only be of 10 Digit.";
    //   }

    //   break;
    case "Bool":
      if (typeof value !== "boolean") {
        resp = " Can Only Be Boolean.";
      }
      break;
    case "Date":
      let newDd = new Date(value);
      if (newDd instanceof Date === false) {
        resp = " is Not a Valid Date.";
      }
      break;
    case "Email":
      // console.log(value);
      if (!validator.isEmail(value)) {
        resp = " is Not a Valid Email.";
      }
      break;
    case "DOB":
      let newD = new Date(value);
      if (!newD instanceof Date) {
        resp = " is Not a Valid Date.";
      }
      let currDate = new Date();
      if (newD > currDate) {
        resp = " is Not a Valid.";
      }
      // Check DOB for Age and DateType
      break;
    case "FirstName":
      if (!validator.isAlpha(value, "en-US")) {
        resp = " Can Not Contain Number.";
      }
      break;
    case "LastName":
      var hasNumber = /d/;
      if (hasNumber.test(value)) {
        resp = " Can Not Contain Number.";
      }
      // Check if its Part of Name
      break;
    case "Mobile":
      let reg = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
      if (!reg.test(value)) {
        resp = value + " is Not a Valid Number.";
      }

      // if (isNaN(value)) {
      //     resp = " Can Only be Number.";
      // }

      // if (typeof value != 'string') {
      //     value = value.toString();
      // }

      // if (value.length < 8) {
      //     resp = " Can Only be of 10 Digit.";
      // }

      break;
    case "Obj":
      if (typeof value !== "object" || value.length < 1) {
        resp = " Can't be Blank.";
      }
      break;
    case "PinCode":
      if (isNaN(value)) {
        resp = " Can Only be Number.";
      }

      if (value.length != 6) {
        resp = " Can Only be of 6 Digit.";
      }

      break;

    case "String":
      if (typeof value !== "string") {
        resp = " Can Only be String.";
      }
      // check string DataType
      break;
    default:
      resp = 1;
  }
  return resp;
}
