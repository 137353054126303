import React from "react";
import { usePagination, DOTS } from "./../hooks/usePagination";

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage === lastPage) return;
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage === 1) return;
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      <div className="row jse my-4">
        <div className="pagination">
          <span className={{
            disabled: currentPage === 1,
            prev: true
          }} onClick={onPrevious}>
            <i className="fa fa-chevron-left"></i>
          </span>
          {paginationRange.map((pageNumber, k) => {
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === DOTS) {
              // <span className="p-link">3</span>
              return <span key={k}>...</span>
            }

            return (
              <span key={k}
                className={
                  pageNumber === currentPage ? "p-link active" : "p-link"
                }
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </span>
            );
          })}
          <span className="next" onClick={onNext}>
            <i className="fa fa-chevron-right"></i>
          </span>
        </div>
      </div>
    </>
  );
};

export default Pagination;
