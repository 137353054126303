import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import Pagination from "../../components/Pagination";
import { useDispatch } from "react-redux";
import { fetchData } from "../../utils/api/apiHelper";
import { LoadWithSubscriptions } from "../../utils/api/actionConstants";
import { CreatorSubscriptions } from "../../hooks/storeHooks";
import { generateRandomString, viewDateDDMMYYY } from "../../utils/Common";
import Images from "../../themes/Images";

const Subscriptions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const subscriptions = CreatorSubscriptions();
  let PageSize = 10;

  useEffect(() => {
    dispatch(
      fetchData({
        action: LoadWithSubscriptions,
        data: {
          limit: 10,
          offset: (currentPage - 1) * PageSize,
        },
      })
    );
  }, [dispatch, currentPage]);

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="d-flex aic jsb flw">
                  <h1 className="pageHeading my-4">Subscription Management</h1>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive p-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="tal pl-4">Name</th>
                                  <th className="tac">Plan</th>
                                  <th className="tac">Start Date</th>
                                  <th className="tac">Renewal Date </th>
                                  <th className="tac">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {subscriptions &&
                                  subscriptions.subscriptions &&
                                  subscriptions.subscriptions.length > 0 &&
                                  subscriptions.subscriptions.map((v, k) => {
                                    return (
                                      <tr key={generateRandomString()}>
                                        <td className="pl-4">
                                          <div className="d-flex aic">
                                            <div className="icon icon-circle">
                                              <div className="icon-user">
                                                <img
                                                  src={v?.user?.profilePic || Images.useIcon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                            <h6 className="text-bold">
                                              <span className="username">
                                                {v?.user?.name}
                                              </span>
                                            </h6>
                                          </div>
                                        </td>
                                        <td className="tac">
                                          <span className="plan">Premium</span>
                                        </td>
                                        <td className="tac">
                                          <span className="startdate d-block">
                                            {viewDateDDMMYYY(v.validFrom)}
                                          </span>
                                        </td>
                                        <td className="tac">
                                          <span className="renewaldate d-block">
                                            {viewDateDDMMYYY(v.validTill)}
                                          </span>
                                        </td>
                                        <td className="tac actbtn">
                                          {v.isCanceled ? (
                                            <span className="btn btn-line">
                                              Canceled
                                            </span>
                                          ) : (
                                            <span className="btn btn-line">
                                              Active
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={subscriptions.totalElements}
                          pageSize={PageSize}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Subscriptions;
