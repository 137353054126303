import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";
import Header from "./Header";
import SideBard from "./SideBard";
import { useDispatch } from "react-redux";
import { fetchReportData } from "../../utils/api/apiHelper";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import { useDashboard } from "../../hooks/storeHooks";
import { LoadDashboardCode } from "../../utils/api/actionConstants";

const Home = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [duration, setDuration] = useState("Week");
  const dashboard = useDashboard();

  useEffect(() => {
    dispatch(
      fetchReportData({
        action: LoadDashboardCode,
        data: {
          action: "newCreator",
        },
      })
    );
    dispatch(
      fetchReportData({
        action: LoadDashboardCode,
        data: {
          action: "activeCreator",
        },
      })
    );
    dispatch(
      fetchReportData({
        action: LoadDashboardCode,
        data: {
          action: "activeConsumer",
        },
      })
    );
    dispatch(
      fetchReportData({
        action: LoadDashboardCode,
        data: {
          action: "openReportRequest",
        },
      })
    );
    dispatch(
      fetchReportData({
        action: LoadDashboardCode,
        data: {
          action: "openWithdrawalRequest",
        },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      fetchReportData({
        action: LoadDashboardCode,
        data: {
          action: "dailyEarning" + duration,
        },
      })
    );
  }, [dispatch, duration]);

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main dashboard">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <h1 className="pageHeading my-4">Welcome To Dashboard</h1>
                <div className="content">
                  <div className="row mb-4">
                    <div className="col-4">
                      <div className="card">
                        <div className="card-body d-flex jsb aic">
                          <div>
                            <div className="icon-circle">
                              <div className="ncsTotal ">
                                <h4>{dashboard?.creators}</h4>
                              </div>
                            </div>
                            <h3 className="mt-3">New Creator Accounts</h3>
                          </div>
                          <div className="icon">
                            <i className="fa fa-users fa-3x"></i>
                          </div>
                        </div>
                        <div className="card-footer">
                          <span
                            className="btn"
                            onClick={(e) => {
                              NavigateTo("/approve-users");
                            }}
                          >
                            Manage
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="card">
                        <div className="card-body d-flex jsb aic">
                          <div>
                            <div className="icon-circle">
                              <div className="report">
                                <h4>{dashboard?.openRequest}</h4>
                              </div>
                            </div>
                            <h3 className="mt-3">Report</h3>
                          </div>
                          <div className="icon">
                            <i className="far fa-chart-bar fa-3x"></i>
                          </div>
                        </div>
                        <div className="card-footer">
                          <span className="btn"
                            onClick={(e) => {
                              NavigateTo("/reportmanagement");
                            }}
                          >
                            Manage
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="card">
                        <div className="card-body d-flex jsb aic">
                          <div>
                            <div className="icon-circle">
                              <div className="pwrTotal ">
                                <h4>{dashboard?.openWithdrawalRequest}</h4>
                              </div>
                            </div>
                            <h3 className="mt-3">
                              Pending Withdrawal Requests
                            </h3>
                          </div>
                          <div className="icon">
                            <i className="far fa-clock fa-3x"></i>
                          </div>
                        </div>
                        <div className="card-footer">
                          <span className="btn"
                            onClick={(e) => {
                              NavigateTo("/withdrawal");
                            }}                          
                          >
                            Manage
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <div className="card">
                        <div className="card-header d-flex jsb">
                          <h2>Daily Earnings</h2>
                          <div>
                            <select
                              name="Daily Earnings"
                              id="DailyEarnings"
                              className="dailyEarnings"
                              value={duration}
                              onChange={(event) => {
                                setDuration(event.target.value);
                              }}
                            >
                              <option value="Week">Week</option>
                              <option value="Month">Month</option>
                              <option value="Year">Year</option>
                            </select>
                          </div>
                        </div>
                        <div className="card-body pb-4">
                          <LineChart
                            width={650}
                            height={400}
                            data={dashboard.earningDuration}
                          >
                            <XAxis dataKey={duration} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="total"
                              stroke="#8884d8"
                            />
                          </LineChart>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="card mb-4 mh-50">
                        <div className="card-body d-flex jsb aic">
                          <div>
                            <div className="icon-circle">
                              <div className="active_creator">
                                <h4>{dashboard?.activeCreator}</h4>
                              </div>
                            </div>
                            <h3 className="mt-3">Active Creator Accounts</h3>
                          </div>
                          <div className="icon">
                            <i className="fa fa-user  fa-3x"></i>
                          </div>
                        </div>
                      </div>
                      <div className="card mh-50">
                        <div className="card-body d-flex jsb aic">
                          <div>
                            <div className="icon-circle">
                              <div className="active_consumer">
                                <h4>{dashboard?.activeConsumer}</h4>
                              </div>
                            </div>
                            <h3 className="mt-3">Active Consumer Accounts</h3>
                          </div>
                          <div className="icon">
                            <i className="fa fa-user fa-3x"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="review_account">
        <footer>
          <div className="copyright">
            <p className="copyrightText">
              Copyright &copy; 2024. All rights reserved.
            </p>
          </div>
        </footer>
        <div className="overlay"></div>
      </div>
    </>
  );
};

export default Home;
