import { Images } from "../../themes";
import { AppRoute } from "../../router/routerConstants";
import { useDispatch, useSelector } from "react-redux";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { saveData } from "../../utils/api/apiHelper";
import { LoginUser } from "../../utils/api/actionConstants";
import { useState } from "react";

const LoginScreen = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRemberMe] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onSetRemberMe = () => {
    setRemberMe(!rememberMe);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      saveData({
        action: LoginUser,
        definition: "Login",
        data: {
          email: email,
          password: password,
        },
      })
    );
    // .then((r) => {
    //   if (r === 1) {
    //     // NavigateTo(AppRoute.REGIS_SUCCESS, true);
    //   }
    // });
  };

  return (
    <>
      <main className="login bg-fill main">
        <div className="container my-auto">
          <div className="row">
            <div className="login_wrapper mx-auto">
              <div className="loginbox my-5">
                <div className="loginLogo tac">
                  <img
                    src={Images.logoColor}
                    alt="Arouzed"
                    width="152"
                    height="166"
                  />
                  <h3 className="my-3">LOGIN</h3>
                </div>
                <form className="loginbody" onSubmit={(e) => handleSubmit(e)}>
                  <div className="input-group my-3">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => onEmailChange(e)}
                      className="form-control"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="input-group my-3">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => onPasswordChange(e)}
                      className="form-control"
                      placeholder="Password"
                    />
                  </div>
                  <div className="d-flex aic jsb flw">
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="rememberMe"
                        onChange={() => onSetRemberMe()}
                        checked={rememberMe}
                        defaultValue={rememberMe}
                      />
                      <label
                        className="form-check-label mb-0 ms-2"
                        htmlFor="rememberMe"
                      >
                        Remember me
                      </label>
                    </div>
                    <div className="form-link d-flex mt-2">
                      {" "}
                      <a href="#"> Reset Password </a>{" "}
                      <span className="divdr"></span>{" "}
                      <a href="#"> Forgot Password? </a>{" "}
                    </div>
                  </div>
                  <div className="form-btn mt-5">
                    <button
                      type="submit"
                      className="btn btn-primary w-100 btn-lg"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <div className="copyright">
          <p className="copyrightText">
            Copyright &copy; 2024. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default LoginScreen;
