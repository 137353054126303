import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import Pagination from "../../components/Pagination";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import { useDispatch } from "react-redux";
import { fetchData, saveData } from "../../utils/api/apiHelper";
import {
  LoadReports,
  SetCurrentCreator,
  ResolveReports,
} from "../../utils/api/actionConstants";
import { useReports } from "../../hooks/storeHooks";
import { generateRandomString, viewDateDDMMYYY, cloneObject } from "../../utils/Common";

const ReportManagement = () => {
  const [NavigateTo] = useCustomNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [selected, setSelected] = useState({});
  const reports = useReports();
  const dispatch = useDispatch();
  let PageSize = 10;

  useEffect(() => {
    dispatch(
      fetchData({
        action: LoadReports,
        data: {
          limit: 10,
          offset: (currentPage - 1) * PageSize,
        },
      })
    );
  }, [dispatch, currentPage]);

  const markComplete = (id) => {
    dispatch(
      saveData({
        action: ResolveReports,
        data: {
          id: id,
        },
      })
    );
  };

  const moveToDetailPage = (v) => {
    let second = cloneObject(v.secondUser);
    if (v.type === "Creator") {
      second.role = {
        name: "Consumer",
      };
    } else {
      second.role = {
        name: "Creator",
      };
    }
    console.log(second)
    dispatch({ type: SetCurrentCreator, payload: second });
    NavigateTo("/view-" + second.role.name.toLowerCase(), false, { usr: second });
  };

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="d-flex aic jsb flw">
                  <h1 className="pageHeading my-4">Report Management</h1>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive p-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="tal pl-4">Report By</th>
                                  <th className="tac">Report For</th>
                                  <th className="tac">Reason</th>
                                  <th className="tac">Date</th>
                                  <th className="tar pr-4">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {reports &&
                                  reports.reports &&
                                  reports.reports.length > 0 &&
                                  reports.reports.map((v, k) => {
                                    return (
                                      <tr key={generateRandomString()}>
                                        <td className="pl-4">
                                          <h6 className="text-bold">
                                            <span className="reportby">
                                              {v?.otherUser?.name}
                                            </span>
                                          </h6>
                                        </td>
                                        <td className="tac">
                                          <h6>
                                            <span className="reportfor">
                                              {v?.secondUser?.name}
                                            </span>
                                          </h6>
                                        </td>
                                        <td className="tac">
                                          <span className="reason">
                                            {v?.reportType}
                                          </span>
                                        </td>
                                        <td className="tac">
                                          <span className="reportdate">
                                            {viewDateDDMMYYY(v.createdAt)}
                                          </span>
                                        </td>
                                        <td className="tar pr-4 actbtn">
                                          {v.reportType === "Others" && (
                                            <span
                                              class="iconbtn popupbtn"
                                              data-target="#reportreason"
                                              onClick={(e) => {
                                                setShowPopup(true);
                                                setSelected(v);
                                              }}
                                            >
                                              <i class="far fa-eye"></i>
                                            </span>
                                          )}
                                          <span
                                            className="iconbtn"
                                            onClick={(e) => {
                                              moveToDetailPage(v);
                                            }}
                                          >
                                            <i className="far fa-user"></i>
                                          </span>{" "}
                                          <span
                                            className="iconbtn"
                                            onClick={(e) => markComplete(v.id)}
                                          >
                                            <i className="far fa-trash-alt"></i>
                                          </span>{" "}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={reports.totalElements}
                          pageSize={PageSize}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {showPopup && (
        <>
          <div
            id="reportreason"
            class="popup"
            style={{
              display: "block",
            }}
          >
            <div className="popup-dialog">
              <div className="popup-content">
                <div className="popup-header">
                  <h4 className="popup-title">Reason</h4>
                  <button
                    type="button"
                    onClick={(e) => {
                      setShowPopup(false);
                      setSelected({});
                    }}
                    className="close"
                    data-dismiss="popup"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="popup-body">
                  <p>{selected.reason}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="overlay"></div>
        </>
      )}
    </>
  );
};

export default ReportManagement;
