import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import Pagination from "./../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, saveData } from "../../utils/api/apiHelper";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import { renderDate } from "../../utils/helper";
import { userData } from "../../redux/userReducer";
import {
  LoadUserToApprove,
  SetCurrentCreator,
  ApproveUser,
} from "../../utils/api/actionConstants";
import { Images } from "../../themes";
import * as CommonFunc from "../../utils/Common";

const ApproveUsers = () => {
  const [NavigateTo] = useCustomNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 10;

  const dispatch = useDispatch();
  const user = useSelector(userData);

  useEffect(() => {
    dispatch(
      fetchData({
        action: LoadUserToApprove,
        data: {
          isApproved: 0,
          limit: 12,
          offset: (currentPage - 1) * PageSize,
        },
      })
    );
  }, [dispatch, currentPage]);

  const onUser = (action, id) => {
    dispatch(
      saveData({
        action: ApproveUser,
        data: {
          id: id,
          status: action === "accept" ? "Approved" : "Rejected",
        },
      })
    );
    if(action == "accept"){
      CommonFunc.showSuccess("User approved successfully");
    }
    if(action != "accept"){
      CommonFunc.showSuccess("User rejected successfully");
    }
  };

  const onViewUser = (v) => {
    dispatch({ type: SetCurrentCreator, payload: v });
    NavigateTo("/view-" + v.role.name.toLowerCase(), false, { usr: v });
  };

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main review">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <h1 className="pageHeading my-4">
                  Review &amp; Approve New Accounts
                </h1>
                <div className="content">
                  <div className="row">
                    {user.userToApprove &&
                      user.userToApprove.length > 0 &&
                      user.userToApprove.map((v, k) => {
                        return (
                          <div className="col-2" key={k}>
                            <div className="card tac">
                              <div className="card-header mx-4 p-3 ">
                                <div className="icon icon-circle icon-lg">
                                  <div className="icon-user">
                                    <img
                                      src={v.profilePic || Images.useIcon}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <h3 className="username mt-3 mb-2">{v.name}</h3>
                                <p className="signDate date">
                                  {renderDate(v.createdAt)}
                                </p>
                              </div>
                              <div className="card-body mt-4">
                                <button
                                  className="btn btn-green mb-3"
                                  onClick={() => onUser("accept", v.id)}
                                >
                                  Accept <span className="btnLoader"></span>
                                </button>
                                <button
                                  className="btn btn-red mb-3"
                                  onClick={() => onUser("reject", v.id)}
                                >
                                  Reject <span className="btnLoader"></span>
                                </button>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => onViewUser(v)}
                                >
                                  View <span className="btnLoader"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={user.totalUsers}
                  pageSize={PageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default ApproveUsers;
