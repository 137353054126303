import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";

import "react-quill/dist/quill.snow.css";

const StaticPage = () => {
  const [NavigateTo] = useCustomNavigate();

  const editClicked = (page) => {
    switch (page) {
      case "1":
        NavigateTo("about", false, { page: "About" });
        break;
      case "2":
        NavigateTo("help", false, { page: "Help" });
        break;
      case "3":
        NavigateTo("privacy", false, { page: "Privacy" });
        break;
      case "4":
        NavigateTo("terms", false, { page: "Terms" });
        break;
      case "5":
        NavigateTo("complaints", false, { page: "Complaints" });
        break;
  
      default:
        break;
    }
  };

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="content mt-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive p-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="tal pl-4">Page</th>
                                  <th className="tar pr-4">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="pl-4">About</td>
                                  <td className="tar pr-4 actbtn">
                                    <span
                                      className="iconbtn"
                                      onClick={() => editClicked("1")}
                                    >
                                      <i className="far fa-edit" />
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="pl-4">Help</td>
                                  <td className="tar pr-4 actbtn">
                                    <span
                                      className="iconbtn"
                                      onClick={() => editClicked("2")}
                                    >
                                      <i className="far fa-edit" />
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="pl-4">Privacy Policy</td>
                                  <td className="tar pr-4 actbtn">
                                    <span
                                      className="iconbtn"
                                      onClick={() => editClicked("3")}
                                    >
                                      <i className="far fa-edit" />
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="pl-4">Terms & Conditions</td>
                                  <td className="tar pr-4 actbtn">
                                    <span
                                      className="iconbtn"
                                      onClick={() => editClicked("4")}
                                    >
                                      <i className="far fa-edit" />
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="pl-4">Complaints</td>
                                  <td className="tar pr-4 actbtn">
                                    <span
                                      className="iconbtn"
                                      onClick={() => editClicked("5")}
                                    >
                                      <i className="far fa-edit" />
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default StaticPage;
