import ReactPlayer from "react-player";

const VideoPlayer = ({url}) => {
  return (
    <ReactPlayer
      width="100%"
      height="100%"
      url={url}
      controls
      config={{
        file: {
          attributes: {
            controlsList: " nodownload  ",
          },
        },
      }}
    />
  );
};

export default VideoPlayer;
