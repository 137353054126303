import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchData, saveData } from "../../utils/api/apiHelper";
import useCustomNavigate from "../../utils/hooks/useCustomNavigate";
import { useStaticPages } from "../../hooks/storeHooks";
import { toast } from "react-toastify";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getStaticPage, saveStaticPage } from "../../utils/api/actionConstants";

const StaticPage1 = () => {
  const [NavigateTo] = useCustomNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  console.log(state);

  const page = state ? state.page : "";

  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");

  const staticPages = useStaticPages();

  const pageData = staticPages ? staticPages[page] : {};

  useEffect(() => {
    setTitle(pageData?.title);
    setValue(pageData?.body);
  }, [pageData]);

  const onTitleChange = (event) => {
    setTitle(event.target.value);
  };

  useEffect(() => {
    dispatch(
      fetchData({
        action: getStaticPage,
        data: {
          page: page,
        },
      })
    );
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
    ],
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (value === "<p><br></p>") {
      toast.error("Please enter data");
      return;
    }

    dispatch(
      saveData({
        action: saveStaticPage,
        data: {
          page: page,
          title: title,
          body: value,
        },
      })
    ).then((r) => {
      if (r === 1) {
        toast.success("Data saved successfully");
        // NavigateTo(AppRoute.REGIS_SUCCESS, true);
      }
    });
  };

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="content mt-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <h3 className="pageTitle mb-3">Page Title</h3>
                          <div className="input-group w-100">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Page Title"
                              value={title}
                              onChange={(e) => onTitleChange(e)}
                            />
                          </div>
                          <div className="pageEditor my-4">
                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              value={value}
                              onChange={setValue}
                            />
                          </div>
                          <div className="form-btn mt-5">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleSubmit}
                            >
                              <i className="far fa-save" /> Save
                              <span className="btnLoader" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default StaticPage1;
