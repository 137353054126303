import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setStorageJson, setStorage } from "../utils/localStorage";
const initialState = {
  About: {},
  Help: {},
  Privacy: {},
  Terms: {},
  Complaints: {},
};

export const staticPagesSlice = createSlice({
  name: "staticPages",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: {
    getStaticPage: (state, action) => {
      const payload = action.payload;
      if (payload) {
        switch (payload.page) {
          case "About":
            state.About = action.payload;
            break;
          case "Help":
            state.Help = action.payload;
            break;
          case "Privacy":
            state.Privacy = action.payload;
            break;
          case "Terms":
            state.Terms = action.payload;
            break;
          case "Complaints":
            state.Complaints = action.payload;
            break;
          default:
            break;
        }
      }
    },
  },
});

export const { getStaticPage } = staticPagesSlice.actions;
export const staticPagesData = (state) => state.staticPages;
export default staticPagesSlice.reducer;
