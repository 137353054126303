import { useLocation } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { Images } from "../../themes";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { userData } from "../../redux/userReducer";

const SideBard = () => {
  const [NavigateTo] = useCustomNavigate();
  const location = useLocation();

  // const dispatch = useDispatch();
  // const current_User = useSelector(userData);

  const navigateTo = (to) => {
    NavigateTo(to);
  };

  const path = location.pathname;

  return (
    <>
      <aside id="sidenav" className="bg-fill">
        <div className="w-auto" id="sidenav-collapse-main">
          <ul className="navbar-nav">
            <li className="nav-item">
              <span
                className={
                  path === "/" ? "nav-link mlink active" : "nav-link mlink"
                }
                onClick={() => navigateTo("/")}
              >
                <i className="fa fa-home"></i>
                <span className="nav-link-text ms-1">Dashboard</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  path === "/approve-users"
                    ? "nav-link mlink active"
                    : "nav-link mlink"
                }
                onClick={() => navigateTo("/approve-users")}
              >
                <i className="far fa-check-circle"></i>
                <span className="nav-link-text ms-1">
                  Review &amp; Approve
                </span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  path === "/user-management"
                    ? "nav-link mlink active"
                    : "nav-link mlink"
                }
                onClick={() => navigateTo("/user-management")}
              >
                <i className="far fa-user"></i>
                <span className="nav-link-text ms-1">User Management</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  path === "/sort-creator"
                    ? "nav-link mlink active"
                    : "nav-link mlink"
                }
                onClick={() => navigateTo("/sort-creator")}
              >
                <i className="far fa-user"></i>
                <span className="nav-link-text ms-1">Creator Reorder</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  path === "/reportmanagement"
                    ? "nav-link mlink active"
                    : "nav-link mlink"
                }
                onClick={() => navigateTo("/reportmanagement")}
              >
                <i className="far fa-chart-bar"></i>
                <span className="nav-link-text ms-1">Report Management</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  path === "/withdrawal"
                    ? "nav-link mlink active"
                    : "nav-link mlink"
                }
                onClick={() => navigateTo("/withdrawal")}
              >
                <i className="fa fa-wallet"></i>
                <span className="nav-link-text ms-1">
                  Wallet Withdrawal Request
                </span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  path === "/payment"
                    ? "nav-link mlink active"
                    : "nav-link mlink"
                }
                onClick={() => navigateTo("/payment")}
              >
                <i className="far fa-credit-card"></i>
                <span className="nav-link-text ms-1">Payment Management</span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  path === "/subscription"
                    ? "nav-link mlink active"
                    : "nav-link mlink"
                }
                onClick={() => navigateTo("/subscription")}
              >
                <i className="fa fa-rocket"></i>
                <span className="nav-link-text ms-1">
                  Subscription Management
                </span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  path === "/staticpage"
                    ? "nav-link mlink active"
                    : "nav-link mlink"
                }
                onClick={() => navigateTo("/staticpage")}
              >
                <i className="far fa-file-alt"></i>
                <span className="nav-link-text ms-1">
                  4 Static Page Content{" "}
                </span>
              </span>
            </li>
            <li className="nav-item">
              <span
                className={
                  path === "/subscriber"
                    ? "nav-link mlink active"
                    : "nav-link mlink"
                }
                onClick={() => navigateTo("/subscriber")}
              >
                <i className="far fa-file-alt"></i>
                <span className="nav-link-text ms-1">
                 Subscribers{" "}
                </span>
              </span>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SideBard;
