import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import Pagination from "./../../components/Pagination";
import { useDispatch } from "react-redux";
import { fetchData } from "../../utils/api/apiHelper";
import { LoadSubscriberForManagement } from "../../utils/api/actionConstants";
import { useSubscribers } from "../../hooks/storeHooks";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Subscriber = () => {

  const [currentPage, setCurrentPage] = useState(1);
  let PageSize = 10;

  const dispatch = useDispatch();
  const subscribers = useSubscribers();

  useEffect(() => {
    dispatch(
        fetchData({
        action: LoadSubscriberForManagement,
        data: {
          limit: 10,
          offset: (currentPage - 1) * PageSize,
        },
      })
    );
  }, [dispatch, currentPage]);

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const fileName = "subscriber";

const exportToCSV = () => {
    const selectedFields = subscribers.allSubscribers.map(({ name, email, userType }) => ({ name, email, userType }));
    const ws = XLSX.utils.json_to_sheet(selectedFields);
    XLSX.utils.sheet_add_aoa(ws, [["Name", "Email", "User Type"]], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};
  return (
    <>
      <Header />
      <main>
      <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="d-flex aic jsb flw">
                  <h1 className="pageHeading my-4">Subscribers List</h1>
                    <div className="searchbar my-4">
                        <div className="input-group d-flex">
                        {subscribers && subscribers.allSubscribers.length ? (
                            <button className="btn btn-primary" onClick={(e) => exportToCSV()}> Export </button>
                          ) : (
                            <button className="btn btn-primary disable-export"> Export </button>
                          )}
                        </div>
                    </div>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body pb-0">
                          <div className="table-responsive p-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th
                                    className="tal pl-4"
                                    style={{ maxWidth: "300px" }}
                                  >
                                    Name
                                  </th>
                                  <th className="tac">Email</th>
                                  <th className="tac">User Type</th>
                                </tr>
                              </thead>
                              <tbody>
                                {subscribers.subscribers &&
                                  subscribers.subscribers.length > 0 &&
                                  subscribers.subscribers.map((v, k) => {
                                    return (
                                      <tr key={k}>
                                        <td className="pl-4">
                                          <div className="d-flex aic">
                                            <h6 className="text-bold">
                                              <span className="username">
                                                {v.name}
                                              </span>
                                            </h6>
                                          </div>
                                        </td>
                                        <td className="tac">
                                          <span className="useremail">
                                            {v.email}
                                          </span>
                                        </td>
                                        <td className="tac">
                                          <span className="usertype">
                                            {v.userType}
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={subscribers.totalElements}
                          pageSize={PageSize}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Subscriber;
