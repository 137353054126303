const Footer = () => {
  return (
    <footer>
      <div className="copyright">
        <p className="copyrightText">
          Copyright &copy; 2024. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
