import { createSlice } from "@reduxjs/toolkit";
// import {
//   setStorageJson,
//   setStorage,
// } from "../utils/localStorage";
const initialState = {
  creators: 0,
  activeCreator: 0,
  activeConsumer: 0,
  openRequest: 0,
  openWithdrawalRequest: 0,
  earningDuration: [],
};

export const folderSlice = createSlice({
  name: "dashboardContent",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: {
    LoadDashboardCode: (state, action) => {
      console.log();
      let payload = action.payload;
      let query = payload.query;
      let data = payload.data;
      if (!data || data.length < 1) return;

      switch (query.action) {
        case "newCreator":
          state.creators = data[0].total;
          break;
        case "activeCreator":
          state.activeCreator = data[0].total;
          break;
        case "activeConsumer":
          state.activeConsumer = data[0].total;
          break;
        case "openReportRequest":
          state.openRequest = data[0].total;
          break;
        case "openWithdrawalRequest":
          state.openWithdrawalRequest = data[0].total;
          break;
        case "dailyEarningMonth":
          state.earningDuration = data;
          break;
        case "dailyEarningWeek":
          state.earningDuration = data;
          break;
        case "dailyEarningYear":
          state.earningDuration = data;
          break;
        default:
          break;
      }
    },
  },
});

export const {} = folderSlice.actions;
export const myDashboardContent = (state) => state.dashboardContent;
export default folderSlice.reducer;
