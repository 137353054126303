import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  setStorageJson,
  setStorage,
} from "../utils/localStorage";
const initialState = {
  folders: [],
  folderR: [],
  folderX: {},
};

export const folderSlice = createSlice({
  name: "myFolder",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: {
    LoadFolder: (state, action) => {
      state.folderR = action.payload.R;
      state.folderX = action.payload.X;
    },
    fetchFolderFiles: (state, action) => {

    }
  },
});

export const { LoadFolder, fetchFolderFiles } = folderSlice.actions;
export const myFolderData = (state) => state.myFolder;
export default folderSlice.reducer;
