const Mustache = require("mustache");

export const replaceValues = (template, data) => {
  return Mustache.render(template, data);
};

export function formatDate(date) {
  if (!date || date == "") {
    return "";
  }

  try {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return year + "-" + month + "-" + day;
  } catch (error) {
    // console.log(error);
    return "";
  }
}

export function getAmPm(date) {
  try {
    let d = new Date(date),
      hour = d.getHours();

    if (hour >= 12) {
      return "PM";
    } else {
      return "AM";
    }
  } catch (error) {
    return "";
  }
}

export function getHourMinute(date) {
  try {
    let d = new Date(date),
      hour = d.getHours(),
      minutes = d.getMinutes();

      if (hour >= 12) {
        hour -= 12; 
      }
    return hour + ":" + minutes;
  } catch (error) {
    return "";
  }
}

export function renderDate(date) {
  try {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return day + "/" + month + "/" + year;
  } catch (error) {
    return "";
  }
}
