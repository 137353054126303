import { useSelector } from "react-redux";
import { Images } from "../../themes";
import { useCustomNavigate } from "../../utils/hooks/useCustomNavigate";
import { userData } from "../../redux/userReducer";
import { removeStorage } from "../../utils/localStorage";
import { useDispatch } from "react-redux";

const Header = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();

  const LogOut = () => {
    removeStorage("user");
    removeStorage("token");
    NavigateTo("/", true);
    window.location.reload(false);

    dispatch({ type: "SIGNOUT_REQUEST" });
  };
  const current_User = useSelector(userData);

  return (
    <>
      <header className="topbar jsb aic d-flex">
        <div className="logo">
          <img
            src={Images.logoWhite}
            className="navbar-brand-img h-100"
            alt="Arouzed"
            width="260"
            height="75"
          />
        </div>
        <div className="mob-logo">
          <img
            src={Images.logoMob}
            className="navbar-brand-img h-100"
            alt="Arouzed"
            width="60"
            height="65"
          />
        </div>
        <div className="d-flex aic me-5">
          <div className="signinUser dropdown">
            <div className=" d-flex aic jse h-100">
              <i className="far fa-user-circle fa-2x"></i>
              <span className="username mx-2">{current_User.user.name}</span>
              <i className="fa fa-angle-down"></i>
            </div>
            <div className="dropdown-menu">
              <ul className="unlist">
                <li>
                  <span className="mlink" onClick={LogOut}>
                    <i className="fa fa-sign-out-alt"></i> Logout
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="menuToggle">
            <span className="toggle">
              <i className="fa fa-bars"></i>
              <i className="fa fa-times"></i>
            </span>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
