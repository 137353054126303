export const AppRoute = {
  SPLASH: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  BENEFITS: "/benefits",
  RESETPASS: "/resetPassword",
  FORGOTPASS: "/forgotPassword",
  REGIS_SUCCESS: "/registrationSuccess",
  FilesSharedWith: "/view-creator/shared-files", 
  CreatorContent: "/view-creator/content",  
  SETUP1: "/setup1",
  SETUP2: "/setup2",
  SETUP3: "/setup3",
  SETUP4: "/setup4",
};
