import "./assets/css/custom.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "react-toastify/dist/ReactToastify.css";

import AppRouter from "./router/AppRouter";

function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
