import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBard from "./SideBard";
import Pagination from "../../components/Pagination";
import { useDispatch } from "react-redux";
import { fetchData } from "../../utils/api/apiHelper";
import { LoadWithPaymentHistory } from "../../utils/api/actionConstants";
import { CreatorPaymentHistory } from "../../hooks/storeHooks";
import Images from "../../themes/Images";
import {
  generateRandomString,
  getAmPm,
  getHourMinute,
  viewDateDDMMYYY,
} from "../../utils/Common";

const PaymentHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const paymentHistory = CreatorPaymentHistory();
  const dispatch = useDispatch();
  let PageSize = 10;

  useEffect(() => {
    dispatch(
      fetchData({
        action: LoadWithPaymentHistory,
        data: {
          limit: 10,
          offset: (currentPage - 1) * PageSize,
        }
      })
    );
  }, [dispatch, currentPage]);

  return (
    <>
      <Header />
      <main>
        <SideBard />
        <div className="main">
          <div className="container">
            <div className="row">
              <div className="content-wrapper w-100 mb-5">
                <div className="d-flex aic jsb flw">
                  <h1 className="pageHeading my-4">Payment History</h1>
                </div>
                <div className="content">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive p-0">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th className="tal pl-4">Name</th>
                                  <th className="tac">Amount</th>
                                  <th className="tac">Date and Time</th>
                                  <th className="tac">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentHistory && paymentHistory.paymentHistory &&
                                  paymentHistory.paymentHistory.length > 0 &&
                                  paymentHistory.paymentHistory.map((v, k) => {
                                    return (
                                      <tr key={generateRandomString()}>
                                        <td className="pl-4">
                                          <div className="d-flex aic">
                                            <div className="icon icon-circle">
                                              <div className="icon-user">
                                                <img
                                                  src={v?.user?.profilePic || Images.useIcon}
                                                  alt=""
                                                />
                                              </div>
                                              {v.type === "Withdrawal" ? (
                                                <div className="icon_withdrawal">
                                                  <i className="far fa-arrow-alt-circle-up"></i>
                                                </div>
                                              ) : (
                                                <div className="icon_deposit">
                                                  <i className="far fa-arrow-alt-circle-down"></i>
                                                </div>
                                              )}
                                            </div>
                                            <h6 className="text-bold">
                                              <span className="username">
                                                {v?.user?.name}
                                              </span>
                                            </h6>
                                          </div>
                                        </td>
                                        <td className="tac">
                                          <span className="amount">
                                            ${v.amount}
                                          </span>
                                        </td>
                                        <td className="tac">
                                          <span className="wdate d-block">
                                            {viewDateDDMMYYY(v.createdAt)}
                                          </span>{" "}
                                          <span className="wtime d-block">
                                            {getHourMinute(v.createdAt)}{" "}
                                            {getAmPm(v.createdAt)}
                                          </span>
                                        </td>
                                        <td className="tac">
                                          <h6>
                                            <span className="reqMode">
                                              {v.type}
                                            </span>
                                          </h6>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={paymentHistory.totalElements}
                          pageSize={PageSize}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PaymentHistory;
